import Link from 'next/link'
import { useEffect, useState } from 'react'
import useStore from '@/utils/state'

const useMenu = function() {
  const profile = useStore(state => state.profile)
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    let _menuItems = [
      <Link key="exams" href="/exams">Practice Exam</Link>,
      <Link key="exams-phase1" href="/exams/phase1">Phase One Exam</Link>,
      <Link key="exams-phase2" href="/exams/phase2">Phase Two Exam</Link>,
      <Link key="exams-phase3" href="/exams/phase3">Phase Three Exam</Link>,
      'separator',
      <Link key="about-us" href="https://certifiedspanish.com/about-us/" target="_blank">About</Link>,
      <Link key="faq" href="https://certifiedspanish.com/faq/" target="_blank">FAQ</Link>,
      <Link key="contact-us" href="https://certifiedspanish.com/contact-us/" target="_blank">Contact</Link>
    ]

    if (profile?.relations?.role?.name === 'Global Administrator') {
      _menuItems = [
        <Link key="admin-grade" href="/admin/grade">Grade Exams</Link>,
        <Link key="admin-user" href="/admin/user">Users</Link>,
        <Link key="admin-question" href="/admin/question">Questions Exams</Link>,
        'separator',
        ..._menuItems]
    }

    setMenuItems(_menuItems)
  }, [setMenuItems, profile])

  return menuItems
}

export default useMenu
